import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Right from '../components/icons/right'
import { Link } from 'gatsby'

const NotFoundPage = () => (
    <Layout>
        <SEO title="Page Not Found" />
        <div className="contact-wrap">
            <h1 className="page-title">PAGE 404&mdash;NOT FOUND</h1>
            <h2 className="page-subtitle">You just hit a route that doesn&#39;t exist... the sadness.</h2>
            <div>
                <Link to="/products" className="css-link">
                    See Products {Right()}
                </Link>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
